<template>
  <v-container fluid fill-height class="down-top-padding">
    <v-row class="align-self-start">
      <v-col cols="12" sm="6">
        <BaseCard heading="Juega Tu Ticktack">
            <div class="mt-0">
                <v-alert
                text
                dense
                color="info"
                icon="mdi-information"
                border="left"
                >Haz click en jugar y comienza a ganar...</v-alert>
                <v-alert
                    text
                    dense
                    type="error"
                    border="left"
                    v-if="viewError"
                ><b>!Atención!</b> {{msgError}}.</v-alert>
                <v-alert
                    text
                    dense
                    type="success"
                    border="left"
                    v-if="viewSuccess"
                    ><b>!Jugada exitosa!</b> ({{msgSuccess}})</v-alert>
            </div>
            <div class="ml-auto py-9">
                <v-select
                class="pt-0 mt-0"
                v-model="selPlan"
                :items="listPlanes"
                :item-text="item =>`${item.nb_plan}  (Invertido: $${item.va_monto}  -  Plazo restante: ${item.plazo_restante} días)`"
                item-value="id_plan"
                label="Selecciones un plan para jugar"
                persistent-hint
                return-object
                single-line
                ></v-select>
            </div>
            <div class="pt-5">
                <button type="button" class="btn btn-rounded btn-sm btn-warning w-100 subtitle-1 font-weight-medium" @click="genPlay"> Jugar </button>
            </div>
        </BaseCard>
      </v-col>
  </v-row>
    <sesionExpiredDialog
      :dialog.sync="dialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="320"
    >
    </sesionExpiredDialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import sesionExpiredDialog from '@/components/mainComponents/SessionExpiredDialog.vue'

export default {
    name: "Play",

    data: () => ({
        monto_inicial: 0,
        msgError: '',
        viewError: false,
        msgSuccess: '',
        viewSuccess: false,
        listPlanes: [],
        selPlan: '',
        dialog: false
    }),

  components: {
    sesionExpiredDialog
  },

    methods: {
        getPlanes() {
            axios({
                method: 'GET',
                url: `${process.env.VUE_APP_API_URL}/api/users/${this.usrData.id}/planes`,
                headers: { 
                    "Authorization": process.env.VUE_APP_TOKEN_TYPE + ' ' + this.usrToken,
                    //"Accept": "application/json"
                }
            })
            .then(async response => {
                //this.loading = false
                let respPlanes = response.data
                console.log('respPlanes...', respPlanes);
                if (!respPlanes.success){
                    this.msgError = respPlanes.data.msg
                    this.viewError = true
                }
                else {
                    this.listPlanes = respPlanes.data.map((plan) => {
                        if(plan.pivot.plazo_restante > 0)
                            return {
                                id_plan: plan.pivot.id,
                                nb_plan: plan.nb_plan,
                                va_monto: plan.pivot.va_monto,
                                va_horas: plan.va_horas,
                                plazo_restante: plan.pivot.plazo_restante,
                            }
                    })
                    //.Transacciones.toLocaleString("es-VE")
                }
                
                this.loading = false
            })
            .catch(error => {
                console.error('respPlanes Error: ', error)
                this.loading = false;
                //let message = !error.response ? error.message : error.response.data.msg;
                if(error.response && error.response.statusText == "Unauthorized"){
                    this.dialog = true
                }
            })

        },
        genPlay() {
            this.viewError= false
            this.viewSuccess= false
            //Obtener los datos del Calculadora de Inversión
            axios({
                method: 'POST',
                url: `${process.env.VUE_APP_API_URL}/api/users/play`,
                params: {
                    tipo_recompensas_id: 1,
                    planes_id: this.selPlan.id_plan,
                    tx_status: 'Gano',
                    wallet_users_id: this.usrData.wallet.id_wallet
                },
                headers: { 
                    "Authorization": process.env.VUE_APP_TOKEN_TYPE + ' ' + this.usrToken,
                    //"Accept": "application/json"
                }
            })
            .then(async response => {
                //this.loading = false
                let respPlay = response.data
                console.log('respPlay...', respPlay);
                if (!respPlay.success){
                    /* VsToast.show({
                        title: 'respPlay de fondos',
                        message: 'Solicitud de respPlay no puede ser procesada.',
                        variant: 'error',
                        timeout: 2000,
                        type: "alert"
                    }); */
                    this.msgError = respPlay.msg
                    this.viewError = true
                }
                else {
                    console.log('respPlay Succes: ', respPlay)
                    this.viewSuccess= true
                    this.msgSuccess = respPlay.msg
                    /*
                    VsToast.show({
                        title: 'respPlay de fondos',
                        message: 'respPlay de fondos cargada exitosamente.',
                        variant: 'success',
                        timeout: 2000,
                        type: "alert"
                    });
                    */
                }
                
            })
            .catch(error => {
                /*
                VsToast.show({
                    title: 'respPlay de fondos',
                    message: 'respPlay de fondos no puede ser procesada.',
                    variant: 'error',
                    timeout: 2000,
                    type: "alert"
                });
                */
                console.error('respPlay Error: ', error)
                this.loading = false;
                //let message = !error.response ? error.message : error.response.data.msg;
                this.msgError = (error.response && error.response.data) ? error.response.data.msg : error.message
                this.viewError = true
                if(error.response && error.response.statusText == "Unauthorized"){
                    this.dialog = true
                }

            })

        },
    },
  
    async created () {
        this.usrData = await JSON.parse(localStorage.getItem("user"))
        this.usrToken = localStorage.getItem("usrToken")
        this.monto_inicial = Number(process.env.VUE_APP_MONTO_MIN_respPlay)
        this.getPlanes()
    },

}
</script>

<style>

</style>